<template>
  <div id="app-container">
    <top-nav @accountClicked="onAccountClick" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <onboarding-modal
      v-if="
        currentSimulation &&
        currentUser &&
        currentUser.onboarding &&
        showingOnboarding &&
        !isSimulationMaster
      "
    />

    <b-modal
      id="account-modal"
      ref="account-modal"
      v-if="currentUser"
      hide-header
      hide-footer
      body-class="text-center account-modal-body"
      size="sm"
    >
      <span v-if="userRoleName" class="role-badge">
        <b-badge variant="warning" pill>
          {{ userRoleName }}
        </b-badge>
      </span>
      <b-avatar
        :src="currentUser.avatar ? currentUser.avatar : ''"
        size="5em"
        :text="currentUser.fullName ? getNameInitials(currentUser.fullName) : ''"
        class="d-block m-auto no-border"
      >
      </b-avatar>
      <h2 class="mt-3 font-light">{{ currentUser.fullName }}</h2>
      <p class="font-extra-light text-muted">{{ currentUser.email }}</p>
    </b-modal>
    <notification-container></notification-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { helperMixin } from '@/mixins/helperMixin'
import Footer from '@/containers/Footer'
import NotificationContainer from '@/components/Notification/NotificationContainer.vue'
import OnboardingModal from '@/components/Common/OnboardingModal'
import TopNav from '@/containers/TopNav'
export default {
  components: {
    'top-nav': TopNav,
    'footer-component': Footer,
    'onboarding-modal': OnboardingModal,
    NotificationContainer
  },
  data() {
    return {
      userRoleName: ''
    }
  },
  mixins: [helperMixin],
  methods: {
    ...mapActions([
      'fetchAppConfiguration',
      'fetchRoles',
      'fetchSimulationByCode',
      'fetchSimulations'
    ]),
    async initializeApplication() {
      await Promise.all([this.fetchAppConfiguration(), this.fetchRoles(), this.fetchSimulations()])
    },
    onAccountClick() {
      this.$refs['account-modal'].show()
    }
  },
  computed: {
    ...mapGetters(['accountRoles', 'currentSimulation', 'currentUser']),
    currentUserSimulation() {
      return this.currentUser.simulations.find((item) => item.id === this.currentSimulation.id)
    },
    isSimulationMaster() {
      return this.currentUserSimulation.simulationRoleId === 3
    },
    showingOnboarding() {
      return !['global-user-management', 'my-simulations'].includes(this.$route.name)
    }
  },
  async created() {
    await this.initializeApplication()
  },
  watch: {
    accountRoles: {
      async handler(newValue) {
        if (newValue && newValue.length) {
          this.userRoleName = newValue.find(
            (role) => role.id === this.currentUser.accountRoleId
          ).name
        }
      },
      immediate: true
    },
    $route: {
      async handler(to) {
        if (
          to &&
          this.checkUrl(to.path) &&
          !this.currentSimulation &&
          to.name !== 'global-user-management'
        ) {
          await this.fetchSimulationByCode(this.code)
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.2s;
}

.fade-enter-active {
  transition-delay: 0.2s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.account-modal-body {
  .role-badge {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
  }
}
</style>
