<template>
  <nav class="navbar fixed-top">
    <div class="d-flex navbar-left logo-container" @click="onLogoClick">
      <logo class="logo align-self-center mx-2" />
      <h6 class="m-0 align-self-center">
        <span v-if="selectedSimulation">{{ $t('general.sitename') }}</span>
        <span v-else>Perform Simulation</span>
      </h6>
    </div>
    <div v-if="hasCurrentSimulation" class="align-self-center d-flex ml-3">
      <base-entity-selection
        v-model="selectedSimulation"
        :disabled="hasOneSimulation"
        :items="simulations"
        @changed="onSimulationChange"
      ></base-entity-selection>
    </div>
    <div class="ml-auto d-flex">
      <div v-if="!loadingSimulation" class="d-flex align-self-center">
        <div v-if="hasCurrentSimulation" class="menu d-flex align-self-center">
          <div
            class="align-self-center icon-menu-item d-flex mx-3"
            v-for="item in filteredMenuItems"
            :key="`item-${item.id}`"
          >
            <router-link
              :class="{ 'router-link-exact-active': $route.path.includes(item.to) }"
              :to="{ name: item.to, params: { dynamic_code: currentSimulation.code } }"
              class="mb-0 align-self-center"
            >
              {{ item.label }}
            </router-link>
          </div>
          <div class="align-self-center d-flex language-switcher ml-3">
            <language-selection
              class="mb-0 align-self-center justify-content-end"
              v-model="selectedLanguage"
              @changed="onLanguageChange"
            ></language-selection>
          </div>
        </div>
        <div v-else class="align-self-center menu d-flex">
          <div class="align-self-center icon-menu-item">
            <router-link :to="{ name: 'my-simulations' }" class="align-self-center">
              <span>My Simulations</span>
            </router-link>
          </div>

          <div v-if="canManageGlobalUsers" class="align-self-center icon-menu-item">
            <router-link :to="{ name: 'global-user-management' }" class="align-self-center">
              <span>Global User Management</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="user align-self-center ml-3">
        <b-dropdown
          class="dropdown-menu-right"
          right
          variant="empty"
          toggle-class="p-0"
          menu-class="mt-1 btn-shadow"
          no-caret
        >
          <template slot="button-content">
            <b-avatar
              class="no-border"
              size="2.5em"
              :src="currentUser && currentUser.img ? currentUser.img : ''"
              :text="currentUser && currentUser.title ? getNameInitials(currentUser.title) : ''"
            ></b-avatar>
          </template>
          <b-dropdown-item @click="onAccountClick">
            {{ $t('menu.profile.account') }}
          </b-dropdown-item>
          <b-dropdown-item @click="onMySimulationsClick">
            <span>My Simulations</span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="onLogoutClick">
            {{ $t('menu.profile.signout') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getTopMenuItems } from '@/constants/menu'
import { helperMixin } from '@/mixins/helperMixin'
import { languageMixin } from '@/mixins/languageMixin'
import { pusherMixin } from '@/mixins/pusherMixin'
import BaseEntitySelection from '@/components/Selections/BaseEntitySelection.vue'
import Logo from '@/components/Svg/Logo.vue'
export default {
  components: {
    BaseEntitySelection,
    Logo
  },
  data() {
    return {
      menuItems: getTopMenuItems(),
      selectedLanguage: null,
      selectedSimulation: null
    }
  },
  mixins: [helperMixin, languageMixin, pusherMixin],
  methods: {
    ...mapActions(['logout', 'setLanguage']),
    ...mapMutations([
      'resetSimulationTeams',
      'resetState',
      'setCurrentSimulation',
      'setCurrentSimulationTeam'
    ]),
    async onLogoutClick() {
      await this.leaveChannels()
      await this.logout()
      await this.resetState()
      await this.$router.push('/user/login')
    },
    onAccountClick() {
      this.$emit('accountClicked')
    },
    onLanguageChange(lan) {
      this.setLanguage(lan.id)
    },
    onLogoClick() {
      if (this.selectedSimulation) {
        this.$router.push({ path: `${this.currentSimulationRoute}` }).catch(() => {})
      } else {
        this.$router.push('/').catch(() => {})
      }
    },
    onMySimulationsClick() {
      if (this.$route.path === '/') {
        return
      }

      this.resetSimulationTeams()
      this.setCurrentSimulation(null)
      this.setCurrentSimulationTeam(null)
      this.selectedSimulation = null
      this.$router.push('/').catch(() => {})
    },
    async onSimulationChange(item) {
      this.resetSimulationTeams()
      await this.setCurrentSimulationTeam(null)
      await this.setCurrentSimulation(item)
      // reset current simulation team
      await this.$router.push(`/my-simulations/${item.code}`).catch(() => {})
    },
    routeTo() {
      this.$router.push('/account')
    },
    routeToManagement() {
      this.$router.push('/admin/game-control')
    }
  },
  computed: {
    ...mapGetters(['currentLanguage', 'currentSimulation', 'currentUser', 'simulations']),
    ...mapGetters('loading', ['loadingSimulation']),
    currentSimulationRoute() {
      return `/my-simulations/${this.currentSimulation.code}`
    },
    filteredMenuItems() {
      return this.canManageSimulation
        ? this.menuItems
        : this.menuItems.filter((item) => item.id !== 'management')
    },
    enabledLogoClick() {
      return this.hasCurrentSimulation && this.isCurrentSimulationRoute
    },
    hasCurrentSimulation() {
      return !!this.currentSimulation
    },
    hasOneSimulation() {
      return this.simulations.length === 1
    },
    isCurrentSimulationRoute() {
      return this.$route.name !== 'home' && this.$route.path.includes(this.currentSimulationRoute)
    }
  },
  mounted() {
    this.setCurrentLanguage()
  },
  watch: {
    currentSimulation: {
      handler(newVal) {
        if (newVal) {
          this.selectedSimulation = _.cloneDeep(newVal)
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped>
.logo-container {
  cursor: pointer;
}

.logo {
  margin: 0 0 0 60px;
  h2 {
    margin-bottom: 0;
    font-weight: 600;
  }
}

.menu {
  .icon-menu-item {
    font-size: 1rem;
    color: #545454;
  }

  .nav {
    width: auto;
  }
}

.a-pwc-logo-grid {
  background-image: url('/assets/img/svg/pwc-logo.svg');
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 3rem;
  height: 2.25rem;
  margin: 0 0 0 60px;
}

.router-link-exact-active {
  color: #145388 !important;
  font-weight: 700;
}

.avatar {
  display: block;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 99em;
}
</style>
