<template>
  <b-modal
    class="onboarding-modal"
    id="onboardingModal"
    ref="onboardingModal"
    hide-footer
    hide-header
    modal-class="onboarding-modal"
    size="lg"
    v-model="showModal"
  >
    <b-colxx xxs="12" class="pl-0 pr-0">
      <glide-component :settings="glideBasicOption" @glideClicked="onGlideClick">
        <div class="glide__slide container">
          <div :key="`home-glide-${glideMoved}`">
            <div class="d-flex my-3 justify-content-center">
              <h1 class="text-primary">
                {{ $t('onboarding.page1.title', { appTitle: appTitle }) }}
              </h1>
            </div>
            <div class="d-flex">
              <b-avatar
                src="/assets/img/svg/great-idea.svg"
                size="150"
                class="mx-auto my-4"
                variant="light"
              ></b-avatar>
            </div>
            <div
              class="list-item-heading mx-5 my-4 animate__animated animate__fadeIn"
              v-html="$t('onboarding.page1.content', { appTitle: appTitle })"
            ></div>
          </div>
        </div>
        <div class="glide__slide container" v-if="isUserParticipant">
          <div :key="`member-glide-${glideMoved}`">
            <div class="d-flex my-3 justify-content-center">
              <h1 class="text-primary">
                {{ $t('onboarding.page2.team-member.title') }}
              </h1>
            </div>
            <div class="d-flex">
              <b-avatar
                src="/assets/img/svg/team-work.svg"
                size="150"
                class="mx-auto my-4"
                variant="light"
              ></b-avatar>
            </div>
            <div
              class="list-item-heading mx-5 my-4 animate__animated animate__fadeIn"
              v-html="$t('onboarding.page2.team-member.content')"
            ></div>
          </div>
        </div>
        <div class="glide__slide container" v-if="isUserFacilitator">
          <div :key="`leader-glide-${glideMoved}`">
            <div class="d-flex my-3 justify-content-center">
              <h1 class="text-primary">
                {{ $t('onboarding.page2.team-leader.title') }}
              </h1>
            </div>
            <div class="d-flex">
              <b-avatar
                src="/assets/img/svg/team-work.svg"
                size="150"
                class="mx-auto my-4"
                variant="light"
              ></b-avatar>
            </div>
            <div
              class="list-item-heading mx-5 my-4 animate__animated animate__fadeIn"
              v-html="$t('onboarding.page2.team-leader.content', { appTitle: appTitle })"
            ></div>
          </div>
        </div>
        <div class="glide__slide container">
          <div :key="`final-glide-${glideMoved}`">
            <div class="d-flex flex-column my-3 justify-content-center">
              <h1 class="text-primary align-self-center m-0 p-0">
                {{ $t('onboarding.page3.title', { team: currentUserTeamName }) }}
              </h1>
              <div class="d-flex">
                <b-avatar
                  src="/assets/img/svg/team-success.svg"
                  size="150"
                  class="mx-auto my-4"
                  variant="light"
                ></b-avatar>
              </div>
            </div>
            <div
              class="list-item-heading mx-5 my-4 animate__animated animate__fadeIn"
              v-html="
                $t('onboarding.page3.content', {
                  team: currentUserTeamName,
                  sitename: $t('general.sitename')
                })
              "
            ></div>
            <div class="d-flex list-item-heading mx-5">
              <b-form-checkbox
                id="checkbox-1"
                class="checkbox align-self-center"
                v-model="status"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
              >
                {{ $t('onboarding.button1') }}
              </b-form-checkbox>
              <b-button
                class="align-self-center ml-auto btn-shadow"
                variant="primary"
                size="sm"
                @click="onModalConfirm"
              >
                {{ $t('onboarding.button2') }}
              </b-button>
            </div>
          </div>
        </div>
      </glide-component>
    </b-colxx>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ContentTile from '@/components/Common/ContentTile'
import GlideComponent from '@/components/Carousel/GlideComponent'
import onboardingContents from '@/data/onboardingContents'
export default {
  components: {
    'glide-component': GlideComponent,
    'content-tile': ContentTile
  },
  data() {
    return {
      appTitle: config.VUE_APP_CLIENT || process.env.VUE_APP_CLIENT,
      glideBasicOption: {
        gap: 4,
        perView: 1,
        type: 'slider',
        rewind: false,
        bound: true,
        hideBullets: true
      },
      glideMoved: 0,
      onboardingContents,
      showModal: false,
      status: '0'
    }
  },
  methods: {
    ...mapActions(['updateUserOnboarding']),
    onGlideClick() {
      this.glideMoved += 1
    },
    onModalConfirm() {
      if (this.status === '1') {
        this.updateUserOnboarding()
      }
      this.triggerModal(false)
    },
    triggerModal(value) {
      this.showModal = value
    }
  },
  computed: {
    ...mapGetters(['currentSimulation', 'currentUser']),
    currentUserSimulation() {
      return this.currentUser.simulations.find((item) => item.id === this.currentSimulation.id)
    },
    currentUserTeamName() {
      const simulations = this.currentUser.simulations
      if (!simulations) return null

      const simulation = simulations.find((item) => item.id === this.currentSimulation.id)

      return simulation.simulationTeamName
    },
    isUserParticipant() {
      return this.currentUserSimulation.simulationRoleId === 1
    },
    isUserFacilitator() {
      return this.currentUserSimulation.simulationRoleId === 2
    }
  },
  mounted() {
    this.triggerModal(true)
  }
}
</script>
