const onboardingContents = [
  {
    key: 1,
    title: 'Welcome to ACME',
    subtitle:
      '<img src="/assets/img/svg/innovation.svg" alt="" /><p>Founded in 2019, ACME insurance is a market leading insurance company which prides itself on customer satisfaction and shareholder profitability.</p><p>As a new employee of ACME, we look forward to welcoming you to the team.</p>',
    // thumb: '/assets/img/svg/innovation.svg',
    size: 'lg'
  },
  {
    key: 2,
    title: 'Welcome Team Participants',
    subtitle:
      '<p>As an insurance claims officer, you will be responsible for validating customer claims submitted to ensure they are claimed correctly. When a customer submits a claim, your role will include validating these against the policy coverage documents and making a determination on the claim that should be paid to the customer.</p><p>You have two very important responsibilities:</p><ol><li>To our customers, in ensuring they receive the correct payment amounts.</li><li>To our shareholders, to ensure we maximise revenue and minimise costs by processing as many correct claims as possible.</li></ol>',
    thumb: '/assets/img/svg/mind.svg',
    size: 'lg'
  },
  {
    key: 3,
    title: 'Team Facilitators',
    subtitle:
      '<p>You have the important task of ensuring the wellbeing and smooth running of your team, this includes:</p><ol><li>Assigning work across the team and redistributing work where necessary. </li><li>Providing Team Participants with approvals for any payments that exceed their delegations of authority.</li></ol><p>Your role card in your onboarding pack contains all the pertinent information you require, including your team, your unique user ID as well as any other role considerations</p>',
    thumb: '/assets/img/svg/user_interface.svg',
    size: 'lg'
  },
  {
    key: 4,
    title: 'Role Card',
    subtitle:
      '<p>The diagram demonstrates where some key information can be found on your role card.</p><img src="/assets/img/svg/role_card.svg" alt="" />',
    // thumb: '/assets/img/svg/user_interface.svg',
    size: 'lg'
  }
]

export default onboardingContents
